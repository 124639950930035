import { createGlobalStyle, css } from 'styled-components/macro'
import { DynamicRangeSliderProps } from '@appbaseio/reactivesearch/lib/components/range/DynamicRangeSlider'
import { DynamicRangeSlider as LibraryDynamicRangeSlider } from '@appbaseio/reactivesearch'

import { T } from '@/modules/Language'

import { CollapsedFilterValue } from './CollapsedFilterValue'
import { titleCss } from './styles'

export const wrapperClassName = 'rs-dynamic-range-slider-wrap'

interface Props extends DynamicRangeSliderProps {
  isCollapsed?: boolean
}

export const DynamicRangeSlider = ({ isCollapsed, ...props }: Props) => (
  <>
    <DynamicRangeSliderStyles />
    {isCollapsed && (
      <CollapsedFilterValue
        componentId={props.componentId}
        placeholder={<T>Reactivesearch:unfiltered</T>}
        transformaValue={(value) => value.split(', ').join(' – ')}
      />
    )}
    {/* @ts-ignore */}
    <LibraryDynamicRangeSlider
      className={wrapperClassName} // Set some sensible defaults
      showHistogram={false}
      tooltipTrigger="hover" // But allow overriding via Props
      {...props}
      style={isCollapsed ? { display: 'none' } : {}}
    />
  </>
)

////////////

const DynamicRangeSliderStyles = createGlobalStyle`
  .${wrapperClassName} {
    width: 100%;
    
    & > h2 {
      ${titleCss}
    }

    &&& .slider-tooltip-visible {
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
      
      ${({ theme }) => css`
        background-color: ${theme.palette.primary.main};
      `}
    }

    &&& .slider-tooltip-visible::after {
      ${({ theme }) => css`
        border-color: ${theme.palette.primary.main} transparent transparent
          transparent;
      `}
    }
  }
`
