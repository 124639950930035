import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from 'polished'
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'

import {
  Feature as FacetFeatures,
  SalesByIdsPaymentInfoQuery,
} from '~generated-types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItemSectionIconValue as IconValue,
  ListItemSection as Section,
  ListItem as Wrapper,
} from '@/components/List'
import { BottomSheet } from '@/components/BottomSheet'
import { Chip } from '@/components/Chip'
import { ElasticSales } from '@/modules/Sales'
import { FontWeight } from '@/components/Typography'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

type ListItemProps = {
  data: ElasticSales
  gqlPaymentData: SalesByIdsPaymentInfoQuery['salesAll'][0] | null
  onClick?: (id: string) => any | null | undefined
}

const ListItem = ({
  data: {
    customerName,
    customerType,
    customerNumber,
    estimatedDates,
    reservationDates,
    facet,
    id,
    name,
    orderNumber,
    participants,
    participantNames: participantNamesArray,
    seller,
    sellerShortName,
    state,
    paymentAgreement,
    contextEvent,
  },
  gqlPaymentData,
  onClick,
}: ListItemProps) => {
  const theme = useTheme()
  const { url } = useRouteMatch()

  const [showPreview, setShowPreview] = useState(false)

  const DATE_FORMAT = 'dd DD.MM.YY'

  const dates = estimatedDates || reservationDates || null

  const handleClick = (id: string) => {
    if (!onClick) {
      return
    }

    onClick(id)
  }

  const participantSearchRequest = new URLSearchParams(window.location.search)
    .get('participants-search')
    ?.slice(1, -1)

  const participantNames = participantNamesArray.filter(
    (name) =>
      name !== ' ' &&
      name !== '' &&
      (participantSearchRequest
        ? name.toLowerCase().includes(participantSearchRequest)
        : true)
  )

  return (
    <>
      <Wrapper onClick={() => handleClick(id)}>
        <Section
          style={{ padding: `0 0 0 ${theme.spacing.gutter}` }}
          align="center"
        >
          <Chip color={getColorForState(state)} size="xs" variant="outlined">
            <T>{`SalesDetails:Lifecycle.state.${state}`}</T>
          </Chip>
        </Section>

        <Section style={{ height: 50 }}>
          <FlexColumn
            alignItems="flex-start"
            justifyContent="center"
            noPadding
            style={{ maxWidth: 320 }}
          >
            <small>
              <span style={{ paddingRight: theme.spacing.gutterSmall }}>
                #{orderNumber}
              </span>
              {' | '}

              <FacetLabel bold>{facet.abbreviation}</FacetLabel>
              {paymentAgreement && (
                <Tooltip
                  content={paymentAgreement.name}
                  delay={300}
                  trigger={(triggerProps) => (
                    <>
                      {' | '}
                      <TooltipLabel {...triggerProps}>
                        {paymentAgreement.code}
                      </TooltipLabel>
                    </>
                  )}
                />
              )}
            </small>
            {name && <Title>{name}</Title>}
          </FlexColumn>
        </Section>

        <Section usePlaceholder={' '} style={{ textAlign: 'right' }}>
          {gqlPaymentData && (
            <TotalPrice>
              {Math.trunc(
                gqlPaymentData?.paymentInfo?.totalPrice.amountVatIncluded || 0
              )}{' '}
              €
            </TotalPrice>
          )}
        </Section>

        {facet.features.includes(FacetFeatures.Participants) ? (
          <ClickableSection
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              setShowPreview(true)
            }}
          >
            <FlexColumn>
              {customerName && customerNumber ? (
                <CustomerName>
                  <FontAwesomeIcon
                    icon={
                      customerType === 'PERSON' ? 'user' : ['far', 'building']
                    }
                    size="xs"
                    color={theme.palette.text.light}
                    style={{
                      marginBottom: 1,
                      marginRight: `${theme.spacing.gu(1)}rem`,
                    }}
                  />
                  {customerName}
                </CustomerName>
              ) : null}
              <Participants>
                <FontAwesomeIcon
                  icon="user-group"
                  size="sm"
                  color={theme.palette.text.light}
                  style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                />
                {participants != null ? `${participants}` : null}
                {participantNames.length > 0 && ' – '}
                {participantNames.slice(0, 3).join(', ')}
              </Participants>
            </FlexColumn>
          </ClickableSection>
        ) : (
          <Section>
            {customerName && customerNumber ? (
              <CustomerName>
                <FontAwesomeIcon
                  icon={
                    customerType === 'PERSON' ? 'user' : ['far', 'building']
                  }
                  size="xs"
                  color={theme.palette.text.light}
                  style={{
                    marginBottom: 1,
                    marginRight: `${theme.spacing.gu(1)}rem`,
                  }}
                />
                {customerName}
              </CustomerName>
            ) : null}
          </Section>
        )}

        <Section
          style={{
            color: theme.palette.text.light,
            fontSize: theme.typography.fontSizeSmall,
            whiteSpace: 'nowrap',
          }}
        >
          {dates ? (
            <>
              <IconValue
                icon="right-to-bracket"
                value={moment(dates.start).format(DATE_FORMAT)}
              />
              <IconValue
                icon="right-from-bracket"
                value={moment(dates.end).format(DATE_FORMAT)}
              />
            </>
          ) : null}
        </Section>

        <Section>{sellerShortName ?? seller ?? null}</Section>

        {!!contextEvent ? (
          <ClickableSection
            minimalRightPadding
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              contextEvent && handleClick(contextEvent.id)
            }}
          >
            <FlexRow alignItems="center" justifyContent="space-between">
              <FlexColumn
                alignItems="flex-start"
                justifyContent="center"
                noPadding
                style={{ width: '100%' }}
              >
                <small>
                  <span style={{ paddingRight: theme.spacing.gutterSmall }}>
                    #{contextEvent.number}
                  </span>
                  {' | '}
                  <FacetLabel bold>TAP</FacetLabel>
                  <FontAwesomeIcon
                    size="sm"
                    icon="up-right-from-square"
                    style={{
                      marginBottom: 1,
                      marginLeft: `${theme.spacing.gu(0.5)}rem`,
                    }}
                    color={theme.palette.primary.dark}
                  />
                </small>
                {contextEvent.name && (
                  <Title style={{ maxWidth: 160 }}>{contextEvent.name}</Title>
                )}
              </FlexColumn>
            </FlexRow>
          </ClickableSection>
        ) : (
          <Section />
        )}
      </Wrapper>
      {showPreview && (
        <BottomSheet
          onClose={() => setShowPreview(false)}
          title={`#${orderNumber}${name ? ` – ${name}` : ''}`}
        >
          <SalesDetails
            baseRoute={url}
            id={id}
            viewType="secondary"
            externalEmbeddedSections={['participants']}
          />
        </BottomSheet>
      )}
    </>
  )
}

export default ListItem

const getColorForState = (state: string) => {
  switch (state) {
    case 'CANCELLED':
    case 'CLOSED':
      return 'danger'
    case 'DRAFT':
    case 'TEMPORAL':
      return 'default'
    default:
      return 'primary'
  }
}

////////////

const Title = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const Participants = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const FacetLabel = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeSmall};
    margin: 0 ${theme.spacing.gutterSmall};
  `}
`

const ClickableSection = styled(Section)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    opacity: 0.6;

    ${({ theme }) => css`
      background-color: ${lighten(0.6, theme.palette.primary.extraLight)};
    `}
  }
`

const CustomerName = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 160px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const TotalPrice = styled.span<{ color?: string }>`
  font-weight: 600;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.light};
  `}
`

const TooltipLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.warning.darker};
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
