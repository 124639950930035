import { NavLink, useLocation } from 'react-router-dom'
import React, { ReactElement, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { lighten } from 'polished'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'

import { NewSaleSelector } from './NewSaleSelector'

type Props = {
  l10nKey: string
  to: string
}

export const NewSaleButton: React.FC<Props> = ({
  l10nKey,
  to,
}): ReactElement => {
  const { pathname } = useLocation()
  const [isOpen, setOpen] = useState(false)

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={<NewSaleSelector closeSelector={() => setOpen(false)} />}
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <NavigationLinkWrapper ref={ref}>
          <StyledButton
            activeClassName="is-active"
            isActive={() => pathname.startsWith(to)}
            to={to}
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }}
          >
            <Label>
              <T>{l10nKey}</T>
            </Label>
          </StyledButton>
        </NavigationLinkWrapper>
      )}
    />
  )
}

////////////

const Label = styled.span`
  font-weight: 600;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const NavigationLinkWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const StyledButton = styled(NavLink)`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  position: relative;
  border: 0;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &.is-active {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${lighten(0.05, theme.palette.primary.extraLight)};
    `}
  }

  &.is-active ${Label} {
    font-weight: 700;
  }

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
      background: ${theme.palette.primary.extraLight};
    `}
  }
`
