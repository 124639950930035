import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

import { H4 } from '@/components/Typography'
import { Right } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  children: ReactNode
  renderSearch?: () => ReactNode
  title: string
  titleIcon: string | string[]
}

export const WidgetFrame = ({
  children,
  renderSearch,
  title,
  titleIcon,
}: Props) => {
  const theme = useTheme()

  return (
    <WidgetWrapper>
      <WidgetTitle>
        <H4
          style={{
            color: theme.palette.primary.dark,
            margin: 0,
          }}
        >
          <WidgetIcon icon={titleIcon} />
          {title}
        </H4>
        <Right>{renderSearch && renderSearch()}</Right>
      </WidgetTitle>
      <WidgetBody>{children}</WidgetBody>
    </WidgetWrapper>
  )
}

////////////

const WidgetIcon = (props: any) => {
  const theme = useTheme()

  return (
    <FontAwesomeIcon
      {...props}
      fixedWidth
      style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
      size="sm"
    />
  )
}

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const WidgetTitle = styled.div`
  display: flex;
  align-items: flex-end;
  height: 50px;

  ${({ theme }) => css`
    padding: 0 0 ${theme.spacing.gu(1)}rem;
  `}
`

const WidgetBody = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.white};
  `}
`
