import { gql } from '@apollo/client'

export default gql`
  query SalesByIdsPaymentInfo($ids: [ID!]!) {
    salesAll(ids: $ids) {
      id
      paymentInfo {
        totalPrice {
          amountVatIncluded
        }
      }
    }
  }
`
