import { Route, RouteProps } from 'react-router-dom'

import { Page, PageProps } from './Page'

interface PageRouteProps extends RouteProps, Omit<PageProps, 'children'> {}

export const PageRoute = ({
  pageTitle,
  pageTitleKey,
  ...routeProps
}: PageRouteProps) => (
  <Page pageTitle={pageTitle} pageTitleKey={pageTitleKey}>
    <Route {...routeProps} />
  </Page>
)

export default PageRoute
