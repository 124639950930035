import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import {
  DataSearch,
  DateRange,
  DynamicRangeSlider,
  MultiList,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexRow } from '@/components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

type ListFiltersProps = {
  componentIds: { [key: string]: string }
  getDefaultQuery: () => Record<string, unknown>
  URLParams?: boolean
}

const ListFilters = ({
  componentIds,
  getDefaultQuery,
  URLParams,
}: ListFiltersProps) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <FlexRow style={{ width: '100%' }}>
      <ModalContainer
        isOpen={isModalOpen}
        keepMounted
        placement="left-end"
        referenceElement={({ ref }) => (
          <FlexRow
            ref={ref}
            style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}
          >
            <FilterButton noNudge onClick={() => setModalOpen(true)}>
              <FontAwesomeIcon
                color={theme.palette.primary.main}
                icon="filter"
              />
            </FilterButton>
          </FlexRow>
        )}
        onClose={() => setModalOpen(false)}
        modal={
          <Wrapper style={{ width: 280 }}>
            <FilterSection
              canCollapse
              label={<T>SalesList:ListHeader.filter.seller</T>}
              render={({ isCollapsed }) => (
                <MultiList
                  componentId={componentIds.SELLER}
                  dataField="seller"
                  defaultQuery={getDefaultQuery}
                  filterLabel={translate(
                    'SalesList:ListHeader.seller',
                    language
                  )}
                  isCollapsed={isCollapsed}
                  missingLabel={translate(
                    'SalesList:ListHeader.sellerMissing',
                    language
                  )}
                  showCheckbox
                  showMissing
                  showSearch={false}
                  URLParams
                />
              )}
            />
            <FilterSection
              canCollapse
              label={<T>SalesList:ListHeader.filter.saleState</T>}
              render={({ isCollapsed }) => (
                <MultiList
                  componentId={componentIds.STATE}
                  dataField="state"
                  defaultQuery={getDefaultQuery}
                  filterLabel={translate(
                    'SalesList:ListHeader.filter.saleState',
                    language
                  )}
                  l10nPrefix="SalesDetails:Lifecycle.state"
                  isCollapsed={isCollapsed}
                  showCheckbox
                  showMissing
                  showSearch={false}
                  URLParams
                />
              )}
            />
            <FilterSection
              canCollapse
              isCollapsedInitially
              label={<T>SalesList:ListHeader.filter.estimatedDates</T>}
              render={({ isCollapsed }) => (
                <DateRange
                  componentId={componentIds.DATES}
                  dataField="estimatedDates.start"
                  defaultQuery={getDefaultQuery}
                  filterLabel={translate(
                    'SalesList:ListHeader.estimatedDates',
                    language
                  )}
                  isCollapsed={isCollapsed}
                  URLParams={!!URLParams}
                />
              )}
            />
            <FilterSection
              canCollapse
              isCollapsedInitially
              label={<T>SalesList:ListHeader.filter.participants</T>}
              render={({ isCollapsed }) => (
                <DynamicRangeSlider
                  componentId={componentIds.PARTICIPANTS}
                  dataField="participants"
                  filterLabel={translate(
                    'SalesList:ListHeader.participants',
                    language
                  )}
                  includeNullValues
                  isCollapsed={isCollapsed}
                  rangeLabels={(min, max) => ({
                    end: `${max} ${translate(
                      'SalesList:ListHeader.filter.participantsLabel',
                      language
                    )}`,
                    start: `${min} ${translate(
                      'SalesList:ListHeader.filter.participantsLabel',
                      language
                    )}`,
                  })}
                  URLParams={!!URLParams}
                />
              )}
            />
          </Wrapper>
        }
        zIndex={10003}
      />
      <DataSearch
        autosuggest={false}
        componentId={componentIds.SEARCH}
        dataField={['customerName', 'name', 'orderNumber.text']}
        defaultQuery={getDefaultQuery}
        filterLabel={translate('SalesList:ListControls.search', language)}
        placeholder={translate(
          `SalesList:ListControls.searchPlaceholder.ALL`,
          language
        )}
        queryFormat={'and'}
        showClear
        style={{ flex: 1, marginBottom: `${theme.spacing.gu(1)}rem` }}
        URLParams
      />
    </FlexRow>
  )
}

export default ListFilters

const FilterButton = styled(InnocuousButton)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    padding: 0 ${theme.spacing.gu(1.5)}rem;
  `}

  &:last-child {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
    border: solid 1px ${theme.palette.smoke.dark};
  `}
`
