import AppLayout from './AppLayout'
import AppRoutes from './routes'

const App = () => {
  return (
    <AppLayout>
      <AppRoutes />
    </AppLayout>
  )
}

export default App
