import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

const TreeRow = styled(FlexRow).attrs(() => ({
  alignItems: 'center',
}))`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
  `}

  font-weight: 400;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(0.5)}rem;
    `}
  }
`

export default TreeRow
