import 'react-toastify/dist/ReactToastify.css'

import styled, { css } from 'styled-components/macro'
import { createPortal } from 'react-dom'
import { ReactNode } from 'react'
import { ToastContainer } from 'react-toastify'

export const NotificationService = () => (
  <NotificationPortal>
    <Notification autoClose={15000} closeOnClick={false} draggable={false} />
  </NotificationPortal>
)

type NotificationPortalProps = {
  children: ReactNode
}

const NotificationPortal = ({ children }: NotificationPortalProps) =>
  createPortal(children, document.getElementById('notification-root') as any)

export const Notification = styled(ToastContainer)`
  width: 340px;
  z-index: 10010;

  .Toastify__toast {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }

  .Toastify__toast-body {
    padding: 8px 6px;

    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeBase2};
      font-family: ${theme.typography.fontFamily};
      color: ${theme.palette.text.main};
    `}
  }

  .Toastify__close-button--light {
    opacity: 0.7;
  }

  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
    opacity: 1;
  }

  ${({ theme }) => css`
    .Toastify__toast--error {
      svg {
        color: ${theme.palette.danger.main};
      }
    }

    .Toastify__toast--info {
      svg {
        color: ${theme.palette.primary.main};
      }
    }

    .Toastify__toast--success {
      svg {
        color: ${theme.palette.success.main};
      }
    }

    .Toastify__toast--warning {
      svg {
        color: ${theme.palette.warning.dark};
      }
    }
  `}

  ${({ theme }) => css`
    .Toastify__progress-bar--error {
      background: ${theme.palette.danger.main};
    }

    .Toastify__progress-bar--info {
      background: ${theme.palette.primary.main};
    }

    .Toastify__progress-bar--success {
      background: ${theme.palette.success.main};
    }

    .Toastify__progress-bar--warning {
      background: ${theme.palette.warning.dark};
    }
  `}
`
