import styled, { css } from 'styled-components/macro'

import { ElasticWidget } from './components/ElasticWidget'
import { RecentWidget } from './components/RecentWidget'
import { SalesQuotaWidget } from './components/SalesQuotaWidget'

export const LandingPage = () => (
  <MainWrapper>
    <BigList>
      <ElasticWidget />
    </BigList>
    <SideWrapper>
      <MiniList>
        <RecentWidget />
      </MiniList>
      <MiniList style={{ maxHeight: 345 }}>
        <SalesQuotaWidget />
      </MiniList>
    </SideWrapper>
  </MainWrapper>
)

////////////

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 720px;
  max-height: 100vh;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    padding: ${theme.spacing.gutterBig} ${theme.spacing.gu(5)}rem;
  `}
`
const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 520px;
  min-width: 440px;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const BigList = styled.div`
  flex: 2;
  display: flex;
`

const MiniList = styled.div`
  flex: 1 1 50%;
  display: flex;
  overflow: hidden;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gutter};
    `}
  }
`
