import styled from 'styled-components/macro'

import { WidgetFrame } from './WidgetFrame'

export const SalesQuotaWidget = () => (
  <WidgetFrame title="Omat myyntitavoiteet" titleIcon={['far', 'chart-bar']}>
    <FrameWrapper>
      <iframe
        src="https://datastudio.google.com/embed/reporting/18G7PsBpQPpc3kXO3ciXnSMBZPZ0C4UYn/page/ly5G"
        frameBorder="0"
        title="sales-quota"
      />
    </FrameWrapper>
  </WidgetFrame>
)

////////////

const FrameWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;

  & > iframe {
    flex: 1;
    align-self: stretch;
  }
`
