import styled, { css } from 'styled-components/macro'
import { Moment } from 'moment'
import { useState } from 'react'

import { FlexColumn, FlexRow, Gutter } from '@/components/Layout'
import { List, ListContent } from '@/components/List'
import { H4 } from '@/components/Typography'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { useRouteDate } from '@/utils/hooks'

import {
  OpenAdvancesFilters,
  OpenAdvancesHeader,
  OpenAdvancesListHeader,
  OpenAdvancesListItem,
} from './components'
import { useOpenAdvances } from './useOpenAdvances'

const SIDEBAR_WIDTH = '300px'

export const OpenAdvances = () => {
  const { date, isDefaultDate, setDate } = useRouteDate()

  const isDateSelected = !isDefaultDate

  const [pickerDate, setPickerDate] = useState<Moment | null>(
    isDateSelected ? date : null
  )
  const [selectedSellers, setSelectedSellers] = useState<string[]>([])

  const { error, loading, openAdvances } = useOpenAdvances({
    date: date.format('YYYY-MM-DD'),
    skip: !isDateSelected,
  })

  const sellers = [
    ...new Set(openAdvances.map((r) => r.sellerShortName)),
  ].filter(Boolean) as string[]

  const filteredOpenAdvances = openAdvances.filter(({ sellerShortName }) => {
    if (!selectedSellers.length || !sellerShortName) {
      return true
    }

    return selectedSellers.includes(sellerShortName)
  })

  const renderContent = () => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <Placeholder>
          <T>Reports:OpenAdvances.error</T>
        </Placeholder>
      )
    }

    if (!isDateSelected) {
      return (
        <Placeholder>
          <T>Reports:OpenAdvances.emptyDate</T>
        </Placeholder>
      )
    }

    if (!filteredOpenAdvances.length) {
      return (
        <Placeholder>
          <T>Reports:OpenAdvances.empty</T>
        </Placeholder>
      )
    }

    return (
      <List style={{ height: 'fit-content' }}>
        <OpenAdvancesListHeader />

        <ListContent>
          {filteredOpenAdvances.map((item) => (
            <OpenAdvancesListItem item={item} key={item.invoiceId} />
          ))}
        </ListContent>
      </List>
    )
  }

  return (
    <Gutter type={[0, 5, 10]}>
      <Wrapper>
        <OpenAdvancesHeader
          date={date}
          isDateSelected={isDateSelected}
          loading={loading}
          openAdvances={filteredOpenAdvances}
          sidebarWidth={SIDEBAR_WIDTH}
        />

        <FlexRow>
          <OpenAdvancesFilters
            loading={loading}
            pickerDate={pickerDate}
            selectedSellers={selectedSellers}
            sellers={sellers}
            setDate={setDate}
            setPickerDate={setPickerDate}
            setSelectedSellers={setSelectedSellers}
            sidebarWidth={SIDEBAR_WIDTH}
          />

          {renderContent()}
        </FlexRow>
      </Wrapper>
    </Gutter>
  )
}

//////

const Placeholder = styled(H4)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  overflow: visible;
`
