import { ChangeEvent, CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input } from '../Input'
import { InputAction } from '../InputAction'
import { InputActions } from '../InputActions'
import { InputWrapper } from '../InputWrapper'

type SIWProps = {
  flex?: boolean
  inline?: boolean
  noMargin?: boolean
}
type SProps = {
  children: ReactNode
  disabled?: boolean
  flex?: boolean
  inline?: boolean
  noArrow?: boolean
  noMargin?: boolean
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  style?: CSSProperties
  value: string | number | null
}

const StyledIW = styled(InputWrapper)<SIWProps>`
  ${(props: SIWProps) => props.flex && 'flex: 1;'}
  ${(props: SIWProps) => props.noMargin && 'margin-bottom: 0;'}
  min-width: initial;
  width: ${({ inline }) => (inline ? 'auto' : '100%')};
`

export const SelectPrimitive = styled(Input.withComponent('select'))<any>`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding-right: ${theme.spacing.gu(5)}rem;
    `}

    appearance: none;
  }
`

export const DownCaretInputAction = styled(InputAction)<any>`
  &::after {
    content: '';
    position: relative;
    display: block;
    width: 0;
    height: 0;
    margin: 0 auto;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #111;
  }
`

const Select = ({ flex, inline, noArrow, noMargin, ...props }: SProps) => (
  <StyledIW flex={flex} inline={inline} noMargin={noMargin}>
    <SelectPrimitive {...props} noMargin={noMargin} />
    {noArrow || (
      <InputActions>
        <DownCaretInputAction />
      </InputActions>
    )}
  </StyledIW>
)

export default Select
