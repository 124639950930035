import REFRESH_VAT_ENABLED from './refreshVatEnabled'
import SALES_ASSIGNERS from './salesAssigners'
import SALES_DETAILS from './salesDetails'
import SALES_DETAILS_FOR_PRINT from './salesDetailsForPrint'
import SALES_ISSUES from './salesIssues'

export default Object.freeze({
  REFRESH_VAT_ENABLED,
  SALES_ASSIGNERS,
  SALES_DETAILS,
  SALES_DETAILS_FOR_PRINT,
  SALES_ISSUES,
})

export type {
  SalesAssignersQuery as SalesAssignersPayload,
  SalesDetailsQuery as SalesDetailsPayload,
  SalesDetailsQueryVariables as SalesDetailsVariables,
  SalesDetailsForPrintQuery as SalesDetailsForPrintPayload,
  SalesDetailsForPrintQueryVariables as SalesDetailsForPrintVariables,
  SalesIssuesQuery as SalesIssuesPayload,
  SalesIssuesQueryVariables as SalesIssuesVariables,
  RefreshVatEnabledQuery as RefreshVatEnabledPayload,
  RefreshVatEnabledQueryVariables as RefreshVatEnabledVariables,
} from '~generated-types'
