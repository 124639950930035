import { gql } from '@apollo/client'

const participantPurchaseFragment = gql`
  fragment ParticipantForSalesPrintPurchase on PurchaseProduct {
    id
    product {
      id
      name
    }
    totalPrice {
      amount
      amountVatIncluded
      vatAmount
    }
  }
`

const participantServiceBedFragment = gql`
  ${participantPurchaseFragment}

  fragment ParticipantForSalesPrintServiceBed on ServiceParticipantBed {
    accommodationTarget {
      default
      id
      name
      sortOrder
    }
    dates {
      checkIn {
        date
      }
      checkOut {
        date
      }
    }
    id
    participantRoom {
      id
      roomReservation {
        id
        request {
          room {
            id
            number
          }
        }
      }
    }
    purchaseProduct {
      ...ParticipantForSalesPrintPurchase
    }
  }
`

const participantServicePurchaseFragment = gql`
  ${participantPurchaseFragment}

  fragment ParticipantForSalesPrintServicePurchase on ServicePurchase {
    id
    purchaseProduct {
      ...ParticipantForSalesPrintPurchase
    }
  }
`

export default gql`
  ${participantServiceBedFragment}
  ${participantServicePurchaseFragment}

  fragment ParticipantForSalesPrint on SalesParticipant {
    accommodationRequest
    additionalInfo
    age
    ageCategory {
      abbreviation
      key
      name
      shortName
    }
    allergyDescription
    allergies
    diets
    drugAllergyDescription
    firstName
    id
    lastName
    group
    services {
      ...ParticipantForSalesPrintServiceBed
      ...ParticipantForSalesPrintServicePurchase
    }
  }
`
