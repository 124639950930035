import moment, { Moment } from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { DateRangePicker } from '@/components/TimeControls'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { CollapsedFilterValue } from '../CollapsedFilterValue'
import { titleCss } from '../styles'

type DateRangeType = {
  end: Moment
  start: Moment
}

type Props = {
  componentId: string
  dataField: string
  hideClearButton?: boolean
  isCollapsed?: boolean
  setQuery: (...args: Array<any>) => any
  title?: ReactNode
  value: string | null | undefined
}

const DATE_FORMAT = moment.HTML5_FMT.DATE
const DATE_SEPARATOR = '–' // Use an endash to allow splitting

export const DateRange = ({
  componentId,
  dataField,
  hideClearButton,
  isCollapsed,
  setQuery,
  title,
  value,
}: Props) => {
  const [propsStart, propsEnd] = value ? value.split(DATE_SEPARATOR) : []

  const [end, setEnd] = useState<Moment | null | undefined>(
    propsEnd ? moment(propsEnd) : propsStart ? moment(propsStart) : null
  )
  const [start, setStart] = useState<Moment | null | undefined>(
    propsStart ? moment(propsStart) : null
  )
  const [range, setRange] = useState<DateRangeType | null | undefined>(
    end && start ? { end, start } : null
  )

  useEffect(() => {
    const targetRange = propsStart
      ? { end: moment(propsEnd || propsStart), start: moment(propsStart) }
      : null

    setQuery(getQuery(dataField, targetRange))

    if (targetRange) {
      setEnd(targetRange.end.clone())
      setRange(targetRange)
      setStart(targetRange.start.clone())
    } else {
      setEnd(null)
      setRange(null)
      setStart(null)
    }
  }, [dataField, propsEnd, propsStart, setQuery])

  const handleChangeDates = (dates: {
    startDate: Moment | null | undefined
    endDate: Moment | null | undefined
  }) => {
    const { endDate, startDate } = dates

    setEnd(endDate)
    setStart(startDate)

    const range: DateRangeType | null | undefined = !!startDate
      ? {
          end: (endDate || startDate).clone(),
          start: startDate.clone(),
        }
      : null

    setRange(range)
    setQuery(getQuery(dataField, range))
  }

  return isCollapsed ? (
    <CollapsedFilterValue
      componentId={componentId}
      placeholder={<T>Reactivesearch:unfiltered</T>}
      transformaValue={(value) => {
        const [startStr, endStr] = value.split('–')

        const end = !!endStr ? moment(endStr) : null
        const start = !!startStr ? moment(startStr) : null

        let transformed = ''

        if (start && start.isValid()) {
          transformed += start.format('ll')
        }

        if (end && end.isValid()) {
          transformed += ` – ${end.format('ll')}`
        }

        return transformed || value
      }}
    />
  ) : (
    <>
      {title && <Title>{title}</Title>}
      <DateRangePicker
        setValue={(range) =>
          handleChangeDates({ endDate: range?.to, startDate: range?.from })
        }
        value={end && start && { from: start, to: end }}
      />
      {!hideClearButton && range && (
        <InnocuousButton
          compact
          onClick={() => {
            handleChangeDates({ endDate: null, startDate: null })
          }}
        >
          <T>Reactivesearch:clearSelection</T>
        </InnocuousButton>
      )}
    </>
  )
}

////////////

const Title = styled.h2`
  ${titleCss}
`

const getQuery = (dataField: string, range: DateRangeType | null | undefined) =>
  range
    ? {
        query: {
          range: {
            [dataField]: {
              gte: range.start.format(DATE_FORMAT),
              lte: range.end.format(DATE_FORMAT),
            },
          },
        },
        value: formatRange(range),
      }
    : {
        value: null,
      }

const formatRange = (range: DateRangeType): string => {
  if (range.start.isSame(range.end)) {
    return range.start.format(DATE_FORMAT)
  }

  return `${range.start.format(DATE_FORMAT)}${DATE_SEPARATOR}${range.end.format(
    DATE_FORMAT
  )}`
}
