import styled, { css } from 'styled-components/macro'
import { ChangeEvent } from 'react'

import { localeList, useLanguageContext } from '@/modules/Language'
import { Select } from '@/components/FormControls'
import { useAuthContext } from '@/modules/Auth'
import { useTheme } from '@/theme'

import {
  AppBarLogo,
  LastUsedSales,
  NavbarButton,
  NewSaleButton,
  UserMenuButton,
} from './components'
import { NavItem } from './types'

export type MainNavbarProps = {
  items: NavItem[]
}

export const MainNavbar = ({ items }: MainNavbarProps) => {
  const theme = useTheme()
  const { language, switchLanguage } = useLanguageContext()
  const { signOut, user } = useAuthContext()

  return (
    <Wrapper>
      <LogoWrapper>
        <AppBarLogo
          alt="Willba Logo"
          href="/"
          src={theme.branding.logoWideSrc}
        />
      </LogoWrapper>

      <NavItemsWrapper>
        <NavItems>
          {items.map((item, idx) => (
            <NavbarButton key={`navLink-${item.key}-${idx}}`} item={item} />
          ))}
        </NavItems>
      </NavItemsWrapper>

      <LastUsedSales />

      <NewSaleButton l10nKey="Sales:action.newSale" to={'/'} />

      <LanguageToggleWrapper>
        <LanguageToggle
          noArrow
          noMargin
          value={language}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            switchLanguage(event.target.value)
          }}
        >
          {localeList.map((lang) => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
        </LanguageToggle>
      </LanguageToggleWrapper>

      {user && (
        <UserMenuButton
          displayName={user.displayName}
          email={user.email}
          photoURL={user.photoURL}
          signOut={signOut}
        />
      )}
    </Wrapper>
  )
}

export default MainNavbar

////////////

const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter} 0 ${theme.spacing.gu(5)}rem;
  `}
`

const NavItemsWrapper = styled.nav`
  flex: 1;
  height: 100%;
`

const NavItems = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0;
  padding: 0;
  height: 100%;

  list-style: none;
`

const LanguageToggleWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}
`

const LanguageToggle = styled(Select)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align-last: center;
    font-weight: 500;

    ${({ theme }) => css`
      height: ${theme.spacing.gu(4)}rem;
      border-color: ${theme.palette.smoke.main};
      padding-right: ${theme.spacing.gu(1)}rem;
    `}

    &:hover {
      cursor: pointer;
      border-color: transparent;

      ${({ theme }) => css`
        background: ${theme.palette.primary.extraLight};
      `}
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(8)}rem;
    background: ${theme.palette.white};
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}

  @media print {
    display: none;
  }
`
