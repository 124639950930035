import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { MainNavbar } from '@/components/MainNavbar'
import { useWillbaConfigContext } from '@/config'

type AppLayoutProps = {
  children: ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const { navItems } = useWillbaConfigContext()

  return (
    <Frame>
      <MainNavbar items={navItems} />
      {children}
    </Frame>
  )
}

export default AppLayout

////////////

const Frame = styled(FlexColumn)`
  position: relative;
  flex-shrink: 0;
  height: 100%;
  z-index: 1;
`
