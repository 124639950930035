import React from 'react'

import { T } from '@/modules/Language'

import ListFilters from '@/modules/Sales/components/SalesSearchList/components/ListFilters'
import SalesSearchList from '@/modules/Sales/components/SalesSearchList/SalesSearchList'

const ComponentIds = Object.freeze({
  DATES: 'dates',
  DIMENSIONS: 'dimensions',
  LIST: 'page',
  PARTICIPANTS: 'participants',
  SEARCH: 'sales-search',
  SEARCH_EVENTS: 'events-search',
  SEARCH_PARTICIPANTS: 'participants-search',
  SELLER: 'seller',
  STATE: 'state',
})

const FILTERS = [
  'estimatedDates',
  'saleState',
  'participants',
  'seller',
  'dimensions',
]

type SalesSearchListProps = {
  hideFilters?: boolean
}

export const EnrollmentsSearchList = ({
  hideFilters,
}: SalesSearchListProps) => {
  const defaultQueryFn = () => ({
    query: { term: { type: 'ENROLLMENT' } },
  })

  return (
    <SalesSearchList
      hideFilters={hideFilters}
      componentIds={ComponentIds}
      listTitle={<T>EnrollmentsSearchList:title</T>}
      defaultQueryFn={defaultQueryFn}
      renderFilters={() => (
        <ListFilters
          searchContext="enrollments"
          filterNames={FILTERS}
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          URLParams
        />
      )}
    />
  )
}

export default EnrollmentsSearchList
