import './config'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import {
  GlobalIconsContextProvider,
  GlobalStyles,
  WillbaThemeProvider,
} from '@/theme'
import {
  KeycloakProvider,
  WillbaApolloProvider,
  WillbaConfigContextProvider,
} from '@/config'
import App from '@/modules/App'
import { AuthProvider } from '@/modules/Auth'
import { BasketContextProvider } from '@/components/Basket'
import { DialogServiceProvider } from '@/components/DialogService'
import { ErrorBoundary } from '@/components/Errors'
import { LanguageContextProvider } from '@/modules/Language'
import { NotificationService } from '@/components/NotificationService'

const Root = () => (
  <KeycloakProvider>
    <WillbaApolloProvider>
      <WillbaConfigContextProvider>
        <WillbaThemeProvider>
          <NotificationService />
          <GlobalStyles />
          <GlobalIconsContextProvider>
            <ErrorBoundary>
              <DialogServiceProvider>
                <LanguageContextProvider>
                  <AuthProvider>
                    <DndProvider backend={HTML5Backend}>
                      <Router>
                        <BasketContextProvider>
                          <App />
                        </BasketContextProvider>
                      </Router>
                    </DndProvider>
                  </AuthProvider>
                </LanguageContextProvider>
              </DialogServiceProvider>
            </ErrorBoundary>
          </GlobalIconsContextProvider>
        </WillbaThemeProvider>
      </WillbaConfigContextProvider>
    </WillbaApolloProvider>
  </KeycloakProvider>
)

ReactDOM.render(<Root />, document.getElementById('root') as any)
