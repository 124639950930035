import { Link } from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components/macro'

import {
  List,
  ListContent,
  ListHeader,
  ListHeaderSection,
  ListItem,
  ListItemSection,
} from '@/components/List'
import { Chip } from '@/components/Chip'
import { FlexColumn } from '@/components/Layout'

import { WidgetFrame } from './WidgetFrame'

export const RecentWidget = () => (
  <WidgetFrame title="Viimeksi avattu" titleIcon={['far', 'clock']}>
    <List>
      <ListHeader>
        <ListHeaderSection>Kohde</ListHeaderSection>
        <ListHeaderSection width="6rem">Avausaika</ListHeaderSection>
      </ListHeader>
      <ListContent>
        {recentItems.map((x) => (
          <ListItem key={x.name}>
            <ListItemSection style={{ alignItems: 'center' }}>
              <FlexColumn alignItems="flex-start">
                <Chip size="xs">{x.type}</Chip>
                <Link to={`/search/sales`}>{x.name}</Link>
              </FlexColumn>
            </ListItemSection>
            <ListItemSection width="6rem">
              <TimeBlock>
                <span>{x.time.format('L')}</span>
                <span>klo {x.time.format('LT')}</span>
              </TimeBlock>
            </ListItemSection>
          </ListItem>
        ))}
      </ListContent>
    </List>
  </WidgetFrame>
)

////////////

const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const recentItems = [
  {
    name: '2019 Junnukarateleiri',
    time: moment().subtract(2, 'h'),
    type: 'Tapahtumavaraus',
  },
  {
    name: 'Nemit Oy TyKy-päivät',
    time: moment().subtract(1, 'd').subtract(1, 'h'),
    type: 'Myyntipalveluvaraus',
  },
  {
    name: 'Pajulahden virkistypäivä',
    time: moment().subtract(3, 'd').add(4, 'h').add(16, 'm'),
    type: 'Myyntipalveluvaraus',
  },
  {
    name: 'Tennisturnaus',
    time: moment().subtract(4, 'd').add(1, 'h').add(39, 'm'),
    type: 'Tapahtumavaraus',
  },
  {
    name: 'Kiekkokoulu 2020',
    time: moment().subtract(5, 'd').add(1, 'h').add(39, 'm'),
    type: 'Tapahtumavaraus',
  },
]
