import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { FlexColumn } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { T } from '@/modules/Language'

type Props = {
  displayName: string
  email: string | null
  photoURL: string | null
  signOut: () => void
}

export const UserMenuModal = ({
  displayName,
  email,
  photoURL,
  signOut,
}: Props) => (
  <Modal>
    <FlexColumn alignItems="center" flex={1} justifyContent="center" noPadding>
      <AvatarBackground />

      <AvatarWrapper>
        <Avatar
          alt="User avatar"
          src={photoURL || '/portrait_placeholder.png'}
        />
      </AvatarWrapper>

      <LabelsWrapper>
        <Name>{displayName}</Name>

        {email && <Email>{email}</Email>}
      </LabelsWrapper>

      <SignOutButton
        color="danger"
        onClick={() => signOut()}
        size="small"
        variant="borderless"
      >
        <T>common:user.signOut</T>
      </SignOutButton>
    </FlexColumn>
  </Modal>
)

////////////

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarBackground = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: absolute;
  top: 0;
  width: 100%;
  zindex: 1;

  ${({ theme }) => css`
    background: ${theme.palette.primary.main};
    height: calc(50px + ${theme.spacing.gutterBig});
  `}
`

const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 2;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gutterBig};
    margin-bottom: ${theme.spacing.gutter};
    border: 2px solid ${theme.palette.smoke.main};
  `}
`

const LabelsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`

const Modal = styled(InlineModal)`
  min-width: 240px;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Name = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-top: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Email = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const SignOutButton = styled(Button)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(4)}rem;
  `}
`
